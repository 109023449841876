import React from "react";

export type FileUploadContextState = {
  uploadedFileKey: string | null;
  setUploadedFileKey: (key: string | null) => void;
  uploadedFileName: string | null;
  setUploadedFileName: (name: string | null) => void;
  uploadedFile: File | null;
  setUploadedFile: (file: File | null) => void;
};

export const FileUploadContext = React.createContext<FileUploadContextState>(
  {} as FileUploadContextState,
);
