import React, { FC, useState, useCallback } from "react";
import Sparkles2FillIcon from "@src/ui/icons/18px/sparkles-2-fill";
import { Popover, PopoverContent, PopoverTrigger } from "@src/ui/popover";
import { Button } from "@src/ui/button";
import ChevronDownIcon from "@src/ui/icons/12px/chevron-down";
import CheckmarkIcon from "@src/ui/icons/18px/checkmark";
import { gql } from "@apollo/client";
import {
  useAiAddEmojisToTextMutation,
  useAiSuggestReadingLevelChangesToTextMutation,
  useAiSuggestSpellingAndGrammarChangesToTextMutation,
} from "./AiTextSuggestionsDropdown.generated";
import { useToast } from "@src/hooks/useToast";
import { updateTranslationSet } from "@src/utils/translationSets";
import { TranslationSetFragment } from "@src/fragments.generated";
import Spinner from "@src/deprecatedDesignSystem/components/Spinner";
import RichTextEditor from "../libraryItemDetailPages/course/RichTextEditor";
import { cn } from "@src/ui/lib/utils";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@src/ui/dropdown-menu";
import { isTranslationSetEmpty } from "@src/components/modals/utils";

type Props = {
  messageText: TranslationSetFragment;
  setMessageText: (text: TranslationSetFragment) => void;
};

enum AI_SUGGEST_OPTION {
  ADD_EMOJIS = "Add emojis",
  SIMPLIFY_TEXT = "Simplify text",
  FIX_SPELLING_GRAMMAR = "Fix spelling and grammar",
}

const aiSuggestOptions = [
  AI_SUGGEST_OPTION.ADD_EMOJIS,
  AI_SUGGEST_OPTION.SIMPLIFY_TEXT,
  AI_SUGGEST_OPTION.FIX_SPELLING_GRAMMAR,
];

const AiTextSuggestionsDropdown: FC<Props> = ({
  messageText,
  setMessageText,
}) => {
  const { addErrorToast } = useToast();
  const [aiUpdatedMessageText, setAiUpdatedMessageText] = useState("");
  const [selectedOption, setSelectedOption] =
    useState<AI_SUGGEST_OPTION | null>(null);
  const resetState = useCallback(() => {
    setSelectedOption(null);
    setAiUpdatedMessageText("");
  }, []);
  const [aiAddEmojisToTextMutation] = useAiAddEmojisToTextMutation({
    variables: { text: messageText["en"] },
    onCompleted: (data) => {
      if (!data.AIAddEmojisToText.success) {
        addErrorToast({
          data,
          callsite: "ai_add_emojis",
        });
      } else if (data.AIAddEmojisToText.value) {
        setAiUpdatedMessageText(data.AIAddEmojisToText.value);
      }
    },
  });
  const [aiSimplifyTextMutation] =
    useAiSuggestReadingLevelChangesToTextMutation({
      variables: { text: messageText["en"] },
      onCompleted: (data) => {
        if (!data.AISuggestReadingLevelChangesToText.success) {
          addErrorToast({
            data,
            callsite: "ai_simplify_text",
          });
        } else if (data.AISuggestReadingLevelChangesToText.value) {
          setAiUpdatedMessageText(
            data.AISuggestReadingLevelChangesToText.value,
          );
        }
      },
    });
  const [aiSuggestSpellingAndTextMutation] =
    useAiSuggestSpellingAndGrammarChangesToTextMutation({
      variables: { text: messageText["en"] },
      onCompleted: (data) => {
        if (!data.AISuggestSpellingAndGrammarChangesToText.success) {
          addErrorToast({
            data,
            callsite: "ai_suggest_spelling",
          });
        } else if (data.AISuggestSpellingAndGrammarChangesToText.value) {
          setAiUpdatedMessageText(
            data.AISuggestSpellingAndGrammarChangesToText.value,
          );
        }
      },
    });
  const handleOptionClick = useCallback(
    (option: AI_SUGGEST_OPTION) => {
      setSelectedOption(option);
      switch (option) {
        case AI_SUGGEST_OPTION.ADD_EMOJIS:
          aiAddEmojisToTextMutation();
          break;
        case AI_SUGGEST_OPTION.SIMPLIFY_TEXT:
          aiSimplifyTextMutation();
          break;
        case AI_SUGGEST_OPTION.FIX_SPELLING_GRAMMAR:
          aiSuggestSpellingAndTextMutation();
          break;
      }
    },
    [
      aiAddEmojisToTextMutation,
      aiSimplifyTextMutation,
      aiSuggestSpellingAndTextMutation,
    ],
  );
  const handleAccept = useCallback(() => {
    setMessageText(
      updateTranslationSet({
        ts: messageText,
        lang: "en",
        text: aiUpdatedMessageText,
      }),
    );
    resetState();
  }, [aiUpdatedMessageText, messageText, setMessageText, resetState]);
  return (
    <Popover open={selectedOption !== null}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <PopoverTrigger asChild>
            <Button
              disabled={isTranslationSetEmpty(messageText)}
              variant="ghost"
            >
              <Sparkles2FillIcon className="text-ai" />
              AI Assist
              <ChevronDownIcon />
            </Button>
          </PopoverTrigger>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="min-w-20">
          {aiSuggestOptions.map((x) => (
            <DropdownMenuItem
              key={x}
              onClick={() => {
                handleOptionClick(x);
              }}
            >
              {x}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
        <PopoverContent
          side="bottom"
          className="z-[9999] max-h-[350px] max-w-64 overflow-y-auto"
        >
          {!!selectedOption && (
            <div className="flex flex-col">
              {!aiUpdatedMessageText ? (
                <div className="flex flex-1 items-center justify-center self-stretch">
                  <Spinner />
                </div>
              ) : (
                <div>
                  <div className="mb-3 w-full cursor-not-allowed rounded-lg bg-gray-alpha-4 px-3 py-2">
                    <RichTextEditor
                      disabled={true}
                      text={aiUpdatedMessageText}
                      hasEditContentPermission={false}
                      className={cn(
                        "m-0 truncate text-[14px] leading-[20px] tracking-[-0.006em] text-foreground",
                      )}
                      editorVariant="minimal-headings"
                    />
                  </div>
                  <div className="flex gap-1">
                    <Button
                      onClick={handleAccept}
                      variant="outline"
                      className="flex-1"
                    >
                      <CheckmarkIcon className="size-3 text-ai" />
                      Accept
                    </Button>
                    <Button
                      onClick={resetState}
                      variant="outline"
                      className="flex-1"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </PopoverContent>
      </DropdownMenu>
    </Popover>
  );
};

gql`
  mutation AIAddEmojisToText($text: String!) {
    AIAddEmojisToText(text: $text) {
      value
      success
    }
  }

  mutation AISuggestSpellingAndGrammarChangesToText($text: String!) {
    AISuggestSpellingAndGrammarChangesToText(text: $text) {
      value
      success
    }
  }

  mutation AISuggestReadingLevelChangesToText($text: String!) {
    AISuggestReadingLevelChangesToText(text: $text) {
      value
      success
    }
  }
`;

export default AiTextSuggestionsDropdown;
