import React, { FC, useCallback, useContext } from "react";
import EmojiPicker from "../../ui/EmojiPicker";
import RichTextEditorFormattingToolbar from "./RichTextEditorFormattingToolbar";
import { TiptapEditorContext } from "./contexts/TiptapEditorContext";
import {
  MediaFragment,
  TranslationSetFragment,
} from "@src/fragments.generated";
import AddMediaModal from "@src/components/modals/addMedia/AddMediaModal";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@src/ui/tooltip";
import { Popover, PopoverContent, PopoverTrigger } from "@src/ui/popover";
import AiTextSuggestionsDropdown from "@src/components/aiAssist/AiTextSuggestionsDropdown";
import { Separator } from "@src/ui/separator";
import PhotoOutlineIcon from "@src/ui/icons/18px/photo-outline";
import { Button } from "@src/ui/button";
import EmojiOutlineIcon from "@src/ui/icons/18px/emoji-outline";
import { useAvailableLanguages } from "@src/hooks/useAvailableLanguages";
import { Switch } from "@src/ui/switch";
import { Label } from "@src/ui/label";
import LanguageIcon from "@src/ui/icons/18px/language";

type Props = {
  onMediaChange?: (media?: MediaFragment) => void;
  messageText?: TranslationSetFragment;
  setMessageText?: (text: TranslationSetFragment) => void;
  translationModeEnabled?: boolean;
  setTranslationModeEnabled?: (v: boolean) => void;
  hideRichTextHeaderOptions?: boolean;
};

const EditorActionsOld: FC<Props> = ({
  onMediaChange,
  messageText,
  setMessageText,
  translationModeEnabled,
  setTranslationModeEnabled,
  hideRichTextHeaderOptions,
}) => {
  const tiptapEditorContext = useContext(TiptapEditorContext);
  const onEmojiSelect = useCallback(
    (emoji: string) => {
      if (tiptapEditorContext?.editor) {
        const insertPoint =
          tiptapEditorContext.editor.view?.state?.selection?.anchor || 1;
        tiptapEditorContext.editor
          .chain()
          .focus()
          .insertContentAt(insertPoint, emoji, {
            parseOptions: {
              preserveWhitespace: false,
            },
          })
          .run();
      }
    },
    [tiptapEditorContext?.editor],
  );
  const { nonEnglishLanguagesInUse } = useAvailableLanguages();
  return (
    <TooltipProvider>
      <div
        className={`flex h-full items-center rounded-b-xl bg-background py-0 ${!hideRichTextHeaderOptions ? "pl-2.5 pr-1.5" : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-1">
          <RichTextEditorFormattingToolbar
            hideRichTextHeaderOptions={hideRichTextHeaderOptions}
          />
          {onMediaChange && (
            <Tooltip>
              <TooltipTrigger>
                <AddMediaModal
                  onMediaChange={onMediaChange}
                  title="Add media"
                  trigger={
                    <Button size="icon" variant="ghost">
                      <PhotoOutlineIcon />
                    </Button>
                  }
                />
              </TooltipTrigger>
              <TooltipContent side="bottom">Add media</TooltipContent>
            </Tooltip>
          )}
          <Popover>
            <Tooltip>
              <TooltipTrigger asChild>
                <PopoverTrigger asChild>
                  <Button size="icon" variant="ghost">
                    <EmojiOutlineIcon />
                  </Button>
                </PopoverTrigger>
              </TooltipTrigger>
              <TooltipContent side="bottom">Emoji</TooltipContent>
            </Tooltip>
            <PopoverContent
              side="right"
              align="start"
              className="z-[9999] w-auto p-0"
              sideOffset={5}
            >
              <EmojiPicker onEmojiSelect={onEmojiSelect} />
            </PopoverContent>
          </Popover>
          {messageText && setMessageText && (
            <>
              <div className="mx-2 flex self-stretch py-1">
                <Separator orientation="vertical" />
              </div>
              <div className="flex flex-1 justify-between self-stretch">
                <AiTextSuggestionsDropdown
                  messageText={messageText}
                  setMessageText={setMessageText}
                />
                {nonEnglishLanguagesInUse.length > 0 && (
                  <div className="mr-2 flex items-center gap-2">
                    <LanguageIcon />
                    <Label>Translation mode</Label>
                    <Switch
                      size="sm"
                      checked={translationModeEnabled}
                      onCheckedChange={() =>
                        setTranslationModeEnabled?.(!translationModeEnabled)
                      }
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </TooltipProvider>
  );
};

export default EditorActionsOld;
