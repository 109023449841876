import { format, formatRelative, getHours } from "date-fns";

import { enUS, es, fr, pt } from "date-fns/locale";

import { DateTime } from "luxon";

export const formatDateOnlyShowYearIfNotCurrent = (date: Date): string => {
  const today = new Date();
  if (today.getFullYear() === date.getFullYear()) {
    return format(date, "MMM d");
  }
  return format(date, "MMM d, y");
};

export const isToday = (date: Date): boolean => {
  const today = new Date();
  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
};

export const formatDateShortOnlyShowYearIfNotCurrent = (date: Date): string => {
  if (isToday(date)) return "Today";
  const today = new Date();
  if (today.getFullYear() === date.getFullYear()) {
    return format(date, "L/d");
  }
  return format(date, "L/d/yy");
};

export const formatDateTime = (
  date: Date,
  opts?: { alwaysShowYear: boolean },
): string => {
  const today = new Date();
  if (!opts?.alwaysShowYear && today.getFullYear() === date.getFullYear()) {
    return format(date, "MMM d, h:mm aaa");
  }
  return format(date, "MMM d y, h:mm aaa");
};

export const formatDate = (date: Date): string => {
  const today = new Date();
  if (today.getFullYear() === date.getFullYear()) {
    return format(date, "MMM d");
  }
  return format(date, "MMM d y");
};

export const formatDateAbbreviated = (
  date: Date,
  opts?: { alwaysShowYear: boolean; showTime: boolean },
): string => {
  const today = new Date();
  if (!opts?.alwaysShowYear && today.getFullYear() === date.getFullYear()) {
    return format(date, "MMM d " + (opts?.showTime ? "h:mm a" : ""));
  }
  return format(date, "MMM d yyyy " + (opts?.showTime ? "h:mm a" : ""));
};

type MonthDayAndYear = {
  monthAndDay: string;
  year: string;
};

export const formatDateMonthDay = (date: Date): MonthDayAndYear => {
  const monthAndDay = format(date, "MMM do");
  const year = format(date, "yy");
  return {
    monthAndDay,
    year,
  };
};

export const formatDateMonthDayYearAsString = (date: Date): string => {
  const e = formatDateMonthDay(date);
  return `${e.monthAndDay}${e.year ? ` '${e.year}` : ""}`;
};

export const formatDateMonthDayAsString = (date: Date): string => {
  const e = formatDateMonthDay(date);
  return e.monthAndDay;
};

export const formatTimeAsString = (date: Date): string => {
  return format(date, "h:mm a");
};

export const formatRelativeDate = (date: Date, lang?: Language): string => {
  const today = new Date();
  if (Math.abs(today.getTime() - date.getTime()) < 1000 * 60 * 3) {
    return "Just now";
  }
  const locale = lang ? langToDateFnsLocale[lang] : enUS;
  const relative = formatRelative(date, today, { locale: locale });
  return relative[0].toUpperCase() + relative.slice(1);
};

export const formatRelativeDateNoTime = (
  date: Date,
): string | MonthDayAndYear => {
  const today = new Date();
  const thisYear = today.getFullYear() === date.getFullYear();
  if (!thisYear) {
    return formatDateMonthDay(date);
  }

  const formatRelativeLocale = {
    lastWeek: "'Last' eeee",
    yesterday: "'Yesterday'",
    today: "'Today'",
    tomorrow: "'Tomorrow'",
    nextWeek: "'Next' eeee",
    other: "MMM do",
  };

  const locale = {
    ...enUS,
    // @ts-ignore
    formatRelative: (token) => formatRelativeLocale[token],
  };
  if (Math.abs(today.getTime() - date.getTime()) < 1000 * 60 * 3) {
    return "Just now";
  }
  const relative = formatRelative(date, today, { locale });
  return relative[0].toUpperCase() + relative.slice(1);
};

export const formatDateStringLong = (date: Date): string => {
  return date.toLocaleDateString("en-US", { dateStyle: "long" });
};

export const getTodaysDate = (): string =>
  new Date().toISOString().split("T")[0];

export const getThreeDaysFromNow = (): Date => {
  const today = new Date(getTodaysDate());
  const threeDaysFromNow = today.setDate(today.getDate() + 4);
  return new Date(threeDaysFromNow);
};

export const isValidSendUntilDate = (
  sendUntil: Date | string | undefined,
  scheduledDateTime: Date | string | null | undefined,
): boolean => {
  if (scheduledDateTime) {
    return sendUntil
      ? new Date(sendUntil!) > new Date(scheduledDateTime)
      : true;
  } else {
    return sendUntil ? new Date(sendUntil!) > new Date(Date.now()) : true;
  }
};

export const langToDateFnsLocale: { [key in Language]?: Locale } = {
  en: enUS,
  es: es,
  fr: fr,
  pt: pt,
  km: enUS,
};

export const dateDiffMs = (date1: Date, date2: Date): number => {
  return Math.abs(date1.getTime() - date2.getTime());
};

const datePartsToDateString = (year: number, month: number, day: number) => {
  return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
    2,
    "0",
  )}`;
};

export type CalendarDay = {
  date: string | null;
  dayOfMonth: number | null;
  inFuture: boolean;
};

export type CalendarGrid = CalendarDay[][];

// March 2022 is year=2022, month=2
export const getCalendarGrid = (year: number, month: number): CalendarGrid => {
  // JS dates are 0 indexed so remove 1 to month to use native JS Date
  const jsAdjustedMonth = month - 1;
  const date = new Date(year, jsAdjustedMonth);
  // Day 0 = Sunday; Day 6 = Saturday
  const firstDay = date.getDay();
  const daysInMonth = 32 - new Date(year, jsAdjustedMonth, 32).getDate();
  const firstWeekDays = 7 - firstDay;
  const daysAfterFirstWeek = daysInMonth - firstWeekDays;
  const fullWeeks = Math.floor(daysAfterFirstWeek / 7);
  const lastRowDays = daysAfterFirstWeek - fullWeeks * 7;
  const totalRows = 1 + fullWeeks + (lastRowDays > 0 ? 1 : 0);
  const today = new Date();
  const isCurrentMonth =
    today.getFullYear() === year && today.getMonth() === jsAdjustedMonth;
  return Array.from(Array(totalRows)).map((_, weekIndex) => {
    return Array.from(Array(7)).map((_, dayIndex) => {
      let day = 0;
      if (weekIndex === 0) {
        if (dayIndex >= firstDay) {
          day = dayIndex - firstDay + 1;
        }
      } else if (weekIndex !== fullWeeks + 1) {
        day = 1 + firstWeekDays + dayIndex + (weekIndex - 1) * 7;
      } else {
        day = daysInMonth - lastRowDays + dayIndex + 1;
      }
      let calendarDay: CalendarDay = {
        date: null,
        dayOfMonth: null,
        inFuture: false,
      };
      if (day <= daysInMonth && day > 0) {
        calendarDay = {
          date: datePartsToDateString(year, month, day),
          dayOfMonth: day,
          inFuture: isCurrentMonth && day > today.getDate(),
        };
      }
      return calendarDay;
    });
  });
};

export function getMediumLengthDateTime(dt: string, locale = "en"): string {
  const datetime = DateTime.fromISO(dt).setLocale(locale);
  // Format as Mar 1, 2022
  return datetime.toLocaleString({
    month: "short",
    day: "numeric",
    year: "numeric",
  });
}

export function secondsBetweenDates(d1: Date, d2: Date): number {
  return Math.abs(d2.getTime() - d1.getTime()) / 1000;
}

export function secondsSinceDate(d: Date | null): number {
  if (!d) return 0;
  return secondsBetweenDates(d, new Date());
}

export const getNowForDatetimeLocalHtmlInput = (): string => {
  const now = new Date();
  const offset: number = now.getTimezoneOffset() * 60000; // offset in milliseconds
  const localISOTime: string = new Date(now.getTime() - offset)
    .toISOString()
    .slice(0, 16);
  return localISOTime;
};

export const convertLocalDateTimeWithoutTzToUTCStringWithoutTz = (
  date: Date,
): string => {
  const localDateTime = DateTime.fromJSDate(date, { zone: "local" });
  const utcDateTime = localDateTime.toUTC();
  return utcDateTime.toFormat("yyyy-MM-dd HH:mm:ss");
};

export const convertUTCHourToLocalHour = (hour: number): number => {
  const savedSendAtUTC = new Date().setUTCHours(hour);
  const savedSendAtLocalTime = new Date(savedSendAtUTC).toLocaleString();
  return new Date(savedSendAtLocalTime).getHours();
};

export const getTimeOfDay = (date: Date = new Date()): string => {
  const hour = getHours(date);
  if (hour >= 5 && hour < 12) {
    return "morning";
  } else if (hour >= 12 && hour < 17) {
    return "afternoon";
  } else {
    return "evening";
  }
};
