import {
  ContentMembershipProgressStatus,
  MatchItemVariant,
} from "@src/components/my-training/types";
import { DateTime } from "luxon";
import { PromptType } from "@src/types.generated";
import { PromptInstanceFragment } from "./courseInstance/operations.generated";
import { isRichTextEmpty } from "../libraryItemDetailPages/course/utils/utils";
import { isDateTimeInPast } from "@src/utils/time";
import { MyTrainingSkillInstancePreview_SkillInstanceFragment } from "./MyTrainingSkillInstancePreview.generated";
import { SkillInstanceVerificationStatus } from "./MyTrainingSkillInstanceVerificationBanner";
import {
  MyTrainingCourseInstanceFragment,
  MyTrainingPathContentMembershipInstanceFragment,
  MyTrainingTrainingResourceInstanceFragment,
} from "./courseInstance/contexts/PathInstanceContextProvider.generated";

export const isDatetimeInPast = (dateStr: string): boolean => {
  return DateTime.fromISO(dateStr) < DateTime.now();
};

export const formatDateToMonthDay = (dateStr: string): string => {
  const luxonDate = DateTime.fromISO(dateStr);
  return luxonDate.toFormat("M/d");
};

export const formatDateShort = (
  stringDate: string | null | undefined,
): string => {
  if (!stringDate) return "–";
  return DateTime.fromISO(stringDate).toLocaleString({
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

export function daysFromNow(dt: string): number {
  const datetime = DateTime.fromISO(dt);
  const now = DateTime.now();
  return datetime.diff(now, "days").days;
}

export const filterOutFailedCourseInstances = (
  content: MyTrainingPathContentMembershipInstanceFragment[],
): MyTrainingPathContentMembershipInstanceFragment[] => {
  return content.filter((c) => c.courseInstance?.failed !== true);
};

export const getContentMembershipInstanceSectionCompletionRatio = (
  content: MyTrainingPathContentMembershipInstanceFragment[],
): string => {
  const contentMembershipsExcludingFailed =
    filterOutFailedCourseInstances(content);
  const completed = contentMembershipsExcludingFailed.filter(
    (c) =>
      c.courseInstance?.completedAt ||
      c.trainingResourceInstance?.openedAt ||
      c.skillInstance?.verifiedAt,
  );
  return `${completed.length}/${contentMembershipsExcludingFailed.length}`;
};

export const getCourseIdFromPathContentMembershipInstance = (
  content: MyTrainingPathContentMembershipInstanceFragment,
): number | undefined => {
  return content.courseInstance?.course.id ?? undefined;
};

export const getTotalCourseInstanceFailedAttempts = (params: {
  courseId: number | undefined;
  contentMemberships: MyTrainingPathContentMembershipInstanceFragment[];
}): number => {
  return params.contentMemberships.filter(
    (c) =>
      c.courseInstance?.course.id === params.courseId &&
      c.courseInstance?.failed === true,
  ).length;
};

export const getContentInstanceProgressStatus = (
  contentInstance: Pick<
    MyTrainingCourseInstanceFragment,
    "completedAt" | "startedAt" | "dueDate"
  > &
    Pick<MyTrainingTrainingResourceInstanceFragment, "openedAt">,
): ContentMembershipProgressStatus => {
  if (contentInstance.completedAt || contentInstance.openedAt) {
    return "COMPLETED";
  }
  const pastDue =
    contentInstance.dueDate && isDatetimeInPast(contentInstance.dueDate);
  if (contentInstance.startedAt && pastDue) {
    return "PAST_DUE_AND_STARTED";
  }
  if (contentInstance.startedAt) {
    return "STARTED";
  }
  if (pastDue) {
    return "PAST_DUE";
  }
  return "NOT_STARTED_OR_DUE";
};

export const getTrainingResourceInstanceProgressStatus = (
  trainingResourceInstance: MyTrainingTrainingResourceInstanceFragment,
): ContentMembershipProgressStatus => {
  if (trainingResourceInstance.openedAt) {
    return "COMPLETED";
  } else {
    return "NOT_STARTED_OR_DUE";
  }
};

export const isCourseInstancePromptEmpty = (
  promptInstance: PromptInstanceFragment["prompt"],
): boolean => {
  return (
    (!promptInstance.text ||
      isRichTextEmpty(promptInstance.text.myTranslation)) &&
    promptInstance.type === PromptType.Message
  );
};

export const getSkillInstanceVerificationStatus = (
  skillInstance: MyTrainingSkillInstancePreview_SkillInstanceFragment,
): SkillInstanceVerificationStatus => {
  if (skillInstance.verifiedAt) {
    return "completed";
  }
  return isSkillInstanceReadyForVerification(skillInstance)
    ? "readyForVerification"
    : "pending";
};

export const isSkillInstanceReadyForVerification = (
  skillInstance: MyTrainingSkillInstancePreview_SkillInstanceFragment,
): boolean => {
  return (
    skillInstance.verifiableAt != null &&
    isDateTimeInPast(skillInstance.verifiableAt) &&
    skillInstance.verifiedAt == null
  );
};

export const getDaysFromDate = (date?: string | null): string => {
  if (!date) return "No due date";
  const days = daysFromNow(date);
  if (days >= 0) {
    return days.toFixed().toString();
  } else {
    return "Past due";
  }
};

export const getMatchItemVariant = (
  showAnswer: boolean | undefined,
  isAnswerCorrect: boolean,
): MatchItemVariant => {
  if (!showAnswer) return "brand";
  return isAnswerCorrect ? "green" : "red";
};
