import React, { FC, useContext } from "react";
import { useModal } from "@src/hooks/useModal";
import AddLinkModal from "./AddLinkModal";
import { TiptapEditorContext } from "./contexts/TiptapEditorContext";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@src/ui/tooltip";
import TextBoldIcon from "@src/ui/icons/18px/text-bold";
import { Button } from "@src/ui/button";
import TextItalicIcon from "@src/ui/icons/18px/text-italic";
import OrderedListIcon from "@src/ui/icons/18px/ordered-list";
import UnorderedListIcon from "@src/ui/icons/18px/unordered-list";
import LinkIcon from "@src/ui/icons/18px/link";
import { Separator } from "@src/ui/separator";
import MessageBlastTextStyleAction from "@src/components/libraryItemDetailPages/MessageBlastTextStyleAction";

type Props = {
  hideRichTextHeaderOptions?: boolean;
};

const RichTextEditorFormattingToolbar: FC<Props> = ({
  hideRichTextHeaderOptions = false,
}) => {
  const { showModal } = useModal();
  const tiptapEditorContext = useContext(TiptapEditorContext);
  return (
    <TooltipProvider>
      <div className="flex gap-1">
        {!hideRichTextHeaderOptions && <MessageBlastTextStyleAction />}
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant={
                tiptapEditorContext?.formatting.isBold ? "secondary" : "ghost"
              }
              size="icon"
              onClick={() =>
                tiptapEditorContext?.editor?.chain().focus().toggleBold().run()
              }
            >
              <TextBoldIcon />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="bottom">Bold</TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant={
                tiptapEditorContext?.formatting.isItalic ? "secondary" : "ghost"
              }
              size="icon"
              onClick={() => {
                tiptapEditorContext?.editor
                  ?.chain()
                  .focus()
                  .toggleItalic()
                  .run();
              }}
            >
              <TextItalicIcon />
            </Button>
          </TooltipTrigger>
          <div className="mx-1 py-1">
            <Separator orientation="vertical" />
          </div>
          <TooltipContent side="bottom">Italic</TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant={
                tiptapEditorContext?.formatting.isBulletList
                  ? "secondary"
                  : "ghost"
              }
              size="icon"
              onClick={() => {
                tiptapEditorContext?.editor
                  ?.chain()
                  .focus()
                  .toggleBulletList()
                  .run();
              }}
            >
              <UnorderedListIcon />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="bottom">Bulleted List</TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant={
                tiptapEditorContext?.formatting.isOrderedList
                  ? "secondary"
                  : "ghost"
              }
              size="icon"
              onClick={() => {
                tiptapEditorContext?.editor
                  ?.chain()
                  .focus()
                  .toggleOrderedList()
                  .run();
              }}
            >
              <OrderedListIcon />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="bottom">Numbered List</TooltipContent>
        </Tooltip>
        <div className="mx-1 py-1">
          <Separator orientation="vertical" />
        </div>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => {
                const selection =
                  tiptapEditorContext?.editor?.view.state.selection;
                if (!selection) {
                  return;
                }
                const text =
                  tiptapEditorContext?.editor?.view.state.doc.textBetween(
                    selection?.from,
                    selection?.to,
                    " ",
                  );
                showModal(
                  <AddLinkModal
                    text={text}
                    onComplete={(text, link) => {
                      tiptapEditorContext?.editor
                        ?.chain()
                        .focus()
                        .setLink({ href: link, target: "_blank" })
                        .insertContentAt(
                          {
                            from: selection.from,
                            to: selection.to,
                          },
                          text,
                        )
                        .unsetMark("link")
                        .run();
                    }}
                  />,
                );
              }}
            >
              <LinkIcon />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="bottom">Link</TooltipContent>
        </Tooltip>
      </div>
    </TooltipProvider>
  );
};

export default RichTextEditorFormattingToolbar;
