import { JumpToItem } from "@components/ui/jumpToBar/hooks/useAllJumpToItems";
import Fuse from "fuse.js";
import { useMemo } from "react";

const MAX_ITEMS = 50;

const stripHtml = (html: string | undefined): string => {
  if (!html) return "";
  return html.replace(/<[^>]*>/g, "");
};

const useSearchJumpToItems = (
  jumpToItems: JumpToItem[],
  searchValue: string,
): JumpToItem[] => {
  const cleanedJumpToItems = useMemo(
    () =>
      jumpToItems.map((val) => ({
        ...val,
        cleanSearchHeadline: val?.searchHeadline
          ? stripHtml(val.searchHeadline)?.toLowerCase()
          : undefined,
      })),
    [jumpToItems],
  );
  return useMemo(() => {
    if (searchValue) {
      return new Fuse(cleanedJumpToItems, fuseConfig)
        .search(searchValue)
        .map((m) => m.item)
        .slice(0, MAX_ITEMS);
    } else {
      return cleanedJumpToItems.slice(0, MAX_ITEMS);
    }
  }, [cleanedJumpToItems, searchValue]);
};

export default useSearchJumpToItems;

const fuseConfig: Fuse.IFuseOptions<JumpToItem> = {
  threshold: 0.3,
  keys: ["name", "email", "phoneNumber", "cleanSearchHeadline"],
};
