import React, { useState } from "react";
import { Button } from "@src/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@src/ui/dropdown-menu";
import PlusIcon from "@src/ui/icons/18px/plus";
import ChatBubbleIcon from "@src/ui/icons/18px/chat-bubble-outline";
import useActionPermissions from "@hooks/useActionPermissions";
import useIsFullAdmin from "@hooks/useIsFullAdmin";
import { useModal } from "@hooks/useModal";
import { useIsComplianceOnly } from "@src/hooks/useIsComplianceOnly";
import Text from "@ui/text";
import MessageBlastModal from "@src/components/messaging/MessageBlastModal";
import InviteAdminOrManagerModal from "@src/components/users/modals/inviteOrEditUserModal/InviteAdminOrManagerModal";
import InviteQRCodeModal from "@src/components/modals/invitePeople/InviteQRCodeModal";
import { useRouter } from "next/router";
import BookOpenIcon from "@src/ui/icons/18px/book-open-outline";
import BookmarkIcon from "@src/ui/icons/18px/bookmark-outline";

import ChevronDownIcon from "@src/ui/icons/12px/chevron-down";
import UsersOutlineIcon from "@src/ui/icons/18px/users-outline";
import FileTextOutlineIcon from "@src/ui/icons/18px/file-text-outline";
import CircleCheckmarkOutlineIcon from "@src/ui/icons/18px/circle-checkmark-outline";
import NewModuleModal from "@src/components/contentLibrary/NewModuleModal";
import NewCourseModal from "@src/components/marketplace/NewCourseModal";
import { emptyTranslationSet } from "@src/utils/prompts";
import { useToast } from "@src/hooks/useToast";
import BulkUploadTrainingResourceModal from "@src/components/modals/bulkUploadTrainingResourceModal/BulkUploadTrainingResourceModal";
import NoPermissionsTooltip from "@src/utils/NoPermissionsTooltip";
import { useSidebar } from "@src/ui/sidebar";
import { cn } from "@src/ui/lib/utils";
import { useCheckInsPageHeaderCreateSkillMutation } from "@src/components/library/check-ins/CheckInsPageHeader.generated";
import { Ff, LibraryItemType } from "@src/types.generated";
import { ClipboardEditIcon } from "lucide-react";
import { useFeatureFlag } from "@src/hooks/useFeatureFlag";

const NavMenuAddButton: React.FC = () => {
  const { state } = useSidebar();
  const [open, setOpen] = useState(false);
  const { userActionPermissions } = useActionPermissions();
  const { showModal } = useModal();
  const isComplianceOnly = useIsComplianceOnly();
  const isFullAdmin = useIsFullAdmin();
  const handleModalOpen = (modalComponent: React.ReactNode) => {
    showModal(modalComponent);
    setOpen(false);
  };
  const disableNavMenuAddButton =
    !userActionPermissions?.createContent &&
    !userActionPermissions?.inviteOthers &&
    !userActionPermissions?.sendMessages;
  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <NoPermissionsTooltip
        hasPermission={!disableNavMenuAddButton}
        tooltipSide="right"
      >
        <DropdownMenuTrigger asChild>
          <Button
            variant="outline"
            className="h-9 w-full gap-1.5 overflow-hidden p-0 transition-[width] duration-200 ease-linear"
          >
            <div
              className={cn(
                "relative flex size-4 shrink-0 items-center justify-center rounded-full bg-primary ml-[8px]",
              )}
            >
              <PlusIcon className="size-3 text-background" />
            </div>
            <div
              className={cn(
                "flex flex-1 items-center transition-all duration-200 ease-linear overflow-hidden",
                state === "collapsed"
                  ? "size-0 opacity-0"
                  : "opacity-100 mr-[8px]",
              )}
            >
              <span className="flex flex-1 justify-start">New</span>
              <div className="flex flex-1 items-center justify-end">
                <ChevronDownIcon className="text-muted-foreground" />
              </div>
            </div>
          </Button>
        </DropdownMenuTrigger>
      </NoPermissionsTooltip>
      {!disableNavMenuAddButton && (
        <DropdownMenuContent
          side={state === "collapsed" ? "right" : "bottom"}
          align="start"
          className={
            state === "collapsed" ? "w-48" : "dropdown-menu-content-w-full"
          }
        >
          {!isComplianceOnly && (
            <>
              <CreateContentRows setOpen={setOpen} />
              <NoPermissionsTooltip
                hasPermission={userActionPermissions?.sendMessages}
              >
                <DropdownMenuItem
                  onClick={() => {
                    if (!userActionPermissions?.sendMessages) return;
                    handleModalOpen(<MessageBlastModal />);
                  }}
                >
                  <div className="flex items-center">
                    <ChatBubbleIcon className="mr-2 text-muted-foreground" />
                    <Text type="P2" fontWeight="Regular">
                      Message
                    </Text>
                  </div>
                </DropdownMenuItem>
              </NoPermissionsTooltip>
              <DropdownMenuSub>
                <DropdownMenuSubTrigger
                  disabled={!userActionPermissions?.inviteOthers}
                >
                  <NoPermissionsTooltip
                    hasPermission={userActionPermissions?.inviteOthers}
                  >
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center">
                        <UsersOutlineIcon className="mr-2 text-muted-foreground" />
                        <Text type="P2" fontWeight="Regular">
                          Invite Users
                        </Text>
                      </div>
                    </div>
                  </NoPermissionsTooltip>
                </DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuSubContent>
                    <NoPermissionsTooltip
                      hasPermission={userActionPermissions?.inviteOthers}
                    >
                      <DropdownMenuItem
                        onClick={() => {
                          if (!userActionPermissions?.inviteOthers) return;
                          handleModalOpen(<InviteQRCodeModal />);
                        }}
                      >
                        <Text type="P2" fontWeight="Regular">
                          Trainee
                        </Text>
                      </DropdownMenuItem>
                    </NoPermissionsTooltip>
                    <NoPermissionsTooltip
                      hasPermission={userActionPermissions?.inviteOthers}
                    >
                      <DropdownMenuItem
                        onClick={() => {
                          if (!userActionPermissions?.inviteOthers) return;
                          handleModalOpen(<InviteAdminOrManagerModal />);
                        }}
                      >
                        <Text type="P2" fontWeight="Regular">
                          {isFullAdmin ? "Admin or Manager" : "Manager"}
                        </Text>
                      </DropdownMenuItem>
                    </NoPermissionsTooltip>
                  </DropdownMenuSubContent>
                </DropdownMenuPortal>
              </DropdownMenuSub>
            </>
          )}
        </DropdownMenuContent>
      )}
    </DropdownMenu>
  );
};

type CreateContentRowsProps = {
  setOpen: (open: boolean) => void;
};

const CreateContentRows: React.FC<CreateContentRowsProps> = (props) => {
  const router = useRouter();
  const { showModal, closeModal } = useModal();
  const [createSkillMutation] = useCheckInsPageHeaderCreateSkillMutation();
  const { userActionPermissions } = useActionPermissions();
  const { addErrorToast } = useToast();
  const handleModalOpen = (modalComponent: React.ReactNode) => {
    showModal(modalComponent);
    props.setOpen(false);
  };
  const taskLibraryItemTypeFlagEnabled = useFeatureFlag(Ff.TaskLibraryItemType);

  return (
    <>
      <NoPermissionsTooltip
        hasPermission={userActionPermissions?.createContent}
      >
        <DropdownMenuItem
          onClick={() => {
            if (!userActionPermissions?.createContent) return;
            handleModalOpen(
              <NewModuleModal
                onModuleCreated={(p) => {
                  const libraryItemId = p.libraryItem.id;
                  if (libraryItemId) {
                    router.push({
                      pathname: "/library/library-item/[id]",
                      query: { id: libraryItemId },
                    });
                  }
                }}
              />,
            );
          }}
        >
          <div className="flex items-center">
            <BookOpenIcon className="mr-2 text-muted-foreground" />
            <Text type="P2" fontWeight="Regular">
              Module
            </Text>
          </div>
        </DropdownMenuItem>
      </NoPermissionsTooltip>
      <NoPermissionsTooltip
        hasPermission={userActionPermissions?.createContent}
      >
        <DropdownMenuItem
          onClick={() => {
            if (!userActionPermissions?.createContent) return;
            handleModalOpen(
              <NewCourseModal
                onSelectMarketplaceOption={() => {
                  closeModal();
                  router.push({
                    pathname: "/marketplace",
                  });
                }}
                onCourseCreated={(c) => {
                  closeModal();
                  const libraryItemId = c.libraryItem.id;
                  if (libraryItemId) {
                    router.push({
                      pathname: "/library/library-item/[id]",
                      query: { id: libraryItemId },
                    });
                  }
                }}
              />,
            );
          }}
        >
          <div className="flex items-center">
            <BookmarkIcon className="mr-2 text-muted-foreground" />
            <Text type="P2" fontWeight="Regular">
              Course
            </Text>
          </div>
        </DropdownMenuItem>
      </NoPermissionsTooltip>
      <NoPermissionsTooltip
        hasPermission={userActionPermissions?.createContent}
      >
        <>
          <DropdownMenuItem
            onClick={async () => {
              if (!userActionPermissions?.createContent) return;
              const res = await createSkillMutation({
                variables: {
                  input: {
                    type: LibraryItemType.Skill,
                    name: emptyTranslationSet(),
                    description: emptyTranslationSet(),
                  },
                },
              });
              const libraryItemId =
                res?.data?.createSkill.skill?.libraryItem.id;
              if (libraryItemId) {
                router.push({
                  pathname: "/library/library-item/[id]",
                  query: { id: libraryItemId },
                });
              } else {
                addErrorToast({
                  ...res?.data?.createSkill,
                  callsite: "create_skill_modal",
                });
              }
            }}
          >
            <div className="flex items-center">
              <CircleCheckmarkOutlineIcon className="mr-2 text-muted-foreground" />
              <Text type="P2" fontWeight="Regular">
                Check-in
              </Text>
            </div>
          </DropdownMenuItem>
          {taskLibraryItemTypeFlagEnabled && (
            <DropdownMenuItem
              onClick={async () => {
                if (!userActionPermissions?.createContent) return;
                const res = await createSkillMutation({
                  variables: {
                    input: {
                      type: LibraryItemType.Task,
                      name: emptyTranslationSet(),
                      description: emptyTranslationSet(),
                    },
                  },
                });
                const libraryItemId =
                  res?.data?.createSkill.skill?.libraryItem.id;
                if (libraryItemId) {
                  router.push({
                    pathname: "/library/library-item/[id]",
                    query: { id: libraryItemId },
                  });
                } else {
                  addErrorToast({
                    ...res?.data?.createSkill,
                    callsite: "create_task_modal",
                  });
                }
              }}
            >
              <div className="flex items-center">
                <ClipboardEditIcon className="mr-2 text-muted-foreground" />
                <Text type="P2" fontWeight="Regular">
                  Task
                </Text>
              </div>
            </DropdownMenuItem>
          )}
        </>
      </NoPermissionsTooltip>
      <NoPermissionsTooltip
        hasPermission={userActionPermissions?.createContent}
      >
        <DropdownMenuItem
          onClick={() => {
            if (!userActionPermissions?.createContent) return;
            handleModalOpen(<BulkUploadTrainingResourceModal />);
          }}
        >
          <div className="flex items-center">
            <FileTextOutlineIcon className="mr-2 text-muted-foreground" />
            <Text type="P2" fontWeight="Regular">
              Resource
            </Text>
          </div>
        </DropdownMenuItem>
      </NoPermissionsTooltip>
    </>
  );
};

export default NavMenuAddButton;
