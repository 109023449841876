import { useCallback, useState, useRef, FC, useContext } from "react";
import gql from "graphql-tag";
import { useDropzone } from "react-dropzone";
import { useGetScormPackageUploadDataMutation } from "./AIUploadArticulateModal.generated";
import { useToast } from "@src/hooks/useToast";
import { Button } from "@src/ui/button";
import Spinner from "@src/deprecatedDesignSystem/components/Spinner";
import Text from "@ui/text";
import XIcon from "@src/ui/icons/18px/x";
import FileIcon from "@src/ui/icons/18px/file-outline";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { FileUploadContext } from "@src/contexts/FileUploadContext";

type Props = {
  convertSCORMPackage: (key: string) => void;
  loading: boolean;
};

const AIUploadArticulateModal: FC<Props> = ({
  convertSCORMPackage,
  loading,
}) => {
  const {
    uploadedFile,
    setUploadedFile,
    uploadedFileKey,
    setUploadedFileKey,
    uploadedFileName,
    setUploadedFileName,
  } = useContext(FileUploadContext);
  const { addErrorToast } = useToast();
  const [postingToS3, setPostingToS3] = useState(false);
  const [percentUploadComplete, setPercentUploadComplete] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const postToS3 = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (data: any) => {
      if (!uploadedFile) {
        addErrorToast({ callsite: "ai_upload_articulate" });
        setPostingToS3(false);
        return;
      }
      const key = data.fields.key;
      const worker = new Worker(
        new URL("../../../workers/s3UploadWorker.ts", import.meta.url),
      );
      worker.onmessage = (e) => {
        switch (e.data.type) {
          case "progress":
            setPercentUploadComplete(e.data.percent);
            break;
          case "success":
            setPostingToS3(false);
            setUploadedFileKey(key);
            setUploadedFileName(uploadedFile.name);
            worker.terminate();
            break;
          case "error":
            addErrorToast({ callsite: "ai_upload_articulate" });
            setPostingToS3(false);
            setPercentUploadComplete(0);
            worker.terminate();
            break;
        }
      };
      worker.postMessage({
        file: uploadedFile,
        url: data.url,
        fields: data.fields,
      });
    },
    [addErrorToast, uploadedFile, setUploadedFileKey, setUploadedFileName],
  );

  const [
    getSCORMPackageUploadURL,
    { loading: loadingGetSCORMPackageUploadURL },
  ] = useGetScormPackageUploadDataMutation({
    onCompleted: (data) => {
      if (
        data.getSCORMPackageUploadData.success &&
        data.getSCORMPackageUploadData.data
      ) {
        setPercentUploadComplete(0);
        setPostingToS3(true);
        postToS3(data.getSCORMPackageUploadData.data);
      } else {
        addErrorToast({ callsite: "ai_upload_articulate" });
      }
    },
    onError: () => {
      addErrorToast({ callsite: "ai_upload_articulate" });
    },
  });

  const handleFileSelection = useCallback(
    (selectedFile: File) => {
      setUploadedFile(selectedFile);
      getSCORMPackageUploadURL({ variables: { key: selectedFile.name } });
    },
    [getSCORMPackageUploadURL, setUploadedFile],
  );

  const openFilePicker = useCallback(() => {
    if (uploadedFileKey) {
      convertSCORMPackage(uploadedFileKey);
    } else {
      inputRef.current?.click();
    }
  }, [uploadedFileKey, convertSCORMPackage]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        handleFileSelection(acceptedFiles[0]);
      }
    },
    [handleFileSelection],
  );

  const { getRootProps, isDragActive } = useDropzone({
    useFsAccessApi: true,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    accept: { "application/zip": [".zip"] },
    onDrop,
  });

  const clearUpload = useCallback(() => {
    setUploadedFileKey(null);
    setUploadedFileName(null);
    setUploadedFile(null);
    setPercentUploadComplete(0);
  }, [setUploadedFileKey, setUploadedFileName, setUploadedFile]);

  const isDisabled = loadingGetSCORMPackageUploadURL || loading || postingToS3;

  return (
    <div
      className={`flex h-[320px] w-full flex-col items-center justify-center rounded-lg border-2 border-dashed bg-background
        ${isDragActive && !uploadedFileKey ? "bg-gray-2 ring-2 ring-primary ring-offset-2" : "border-gray-5/50"}`}
      {...(uploadedFileKey ? {} : getRootProps())}
    >
      <div className="flex h-[87px] items-center justify-center">
        <div className="flex flex-col items-center justify-center">
          <Text type="H3" fontWeight="SemiBold" className="text-center">
            Upload Articulate 360 Rise Package
          </Text>
          <Text
            type="P2"
            color={deprecatedTones.gray8}
            className="mt-4 text-center"
          >
            AI Assist will convert your Articulate Rise 360 package into a
            module
          </Text>

          {uploadedFileKey && uploadedFileName && (
            <div className="mt-4 flex items-center gap-2">
              <Button variant="ghost" size="xs" onClick={clearUpload}>
                <XIcon />
              </Button>
              <div className="flex items-center gap-2 rounded-lg border border-dashed border-gray-5 px-3 py-2">
                <FileIcon className="size-4 text-gray-8" />
                <Text type="P2" color={deprecatedTones.gray8}>
                  {uploadedFileName}
                </Text>
              </div>
            </div>
          )}

          {(loadingGetSCORMPackageUploadURL || postingToS3) && (
            <div className="mt-4 flex flex-col items-center justify-center gap-3">
              <Spinner />
              <Text type="P2">Upload {percentUploadComplete}% complete</Text>
            </div>
          )}
          {!isDisabled && !uploadedFileKey && (
            <>
              <div className="mt-3 flex items-center">
                <Button
                  variant="default"
                  onClick={openFilePicker}
                  disabled={isDisabled}
                >
                  Upload ZIP file
                </Button>
                <input
                  ref={inputRef}
                  type="file"
                  accept=".zip"
                  className="hidden"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) handleFileSelection(file);
                  }}
                />
              </div>
              <Text
                type="P2"
                color={deprecatedTones.gray5}
                className="mt-4 text-center"
              >
                Or drag & drop file here
              </Text>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

gql`
  mutation GetSCORMPackageUploadData($key: String!) {
    getSCORMPackageUploadData(key: $key) {
      data
      success
      error {
        code
      }
    }
  }
`;

export default AIUploadArticulateModal;
