import Widget from "@src/components/home/Widget";
import { gql } from "@apollo/client";
import { useMemo } from "react";
import { useActivityWidgetQuery } from "./ActivityWidget.generated";
import { LibraryItemType } from "@src/types.generated";
import { cn } from "@src/ui/lib/utils";
import { Skeleton } from "@src/ui/skeleton";
import Link from "next/link";
import BoltOutlineIcon from "@src/ui/icons/18px/bolt-outline";
import Text, { textVariants } from "@src/ui/text";
import {
  formatDateMonthDayAsString,
  formatTimeAsString,
} from "@src/utils/dates";
import { parseISO } from "date-fns";
import { recentActivityTypeToText } from "@src/utils/copy";
import EmptyWidgetContent from "./EmptyWidgetContent";

const LibraryItemTypeToText: Record<LibraryItemType, string> = {
  [LibraryItemType.Course]: "course",
  [LibraryItemType.Path]: "module",
  [LibraryItemType.Skill]: "check-in",
  [LibraryItemType.PremiumContent]: "premium content",
  [LibraryItemType.Sharp]: "module",
  [LibraryItemType.TrainingResource]: "training resource",
  [LibraryItemType.Task]: "task",
};

const ActivityWidget: React.FC = () => {
  const { data, loading } = useActivityWidgetQuery();

  const activityItems = useMemo(() => data?.RecentActivity ?? [], [data]);
  if (!loading && activityItems.length === 0) {
    return (
      <Widget title="Activity" Icon={BoltOutlineIcon}>
        {!loading && activityItems.length === 0 && (
          <EmptyWidgetContent
            illustration={
              <BoltOutlineIcon className="size-8 text-muted-foreground" />
            }
            description="This will display a real-time feed of what's happening across Opus"
          />
        )}
      </Widget>
    );
  }

  return (
    <Widget title="Activity" Icon={BoltOutlineIcon}>
      {!loading && (
        <>
          <div className="my-auto flex max-h-[300px] w-full flex-col overflow-y-auto">
            {activityItems.map((activityItem, index) => (
              <div
                key={`${activityItem.libraryItem.id}-${recentActivityTypeToText[activityItem.type]}`}
                className={cn(
                  "flex gap-4 py-1 items-center",
                  index < activityItems.length - 1 && "border-b",
                )}
              >
                <div className="flex flex-col">
                  <Text type="P2" fontWeight="Medium">
                    {formatDateMonthDayAsString(
                      parseISO(activityItem.datetime),
                    )}
                  </Text>
                  <Text type="P2" fontWeight="Medium">
                    {formatTimeAsString(parseISO(activityItem.datetime))}
                  </Text>
                </div>
                <p className={cn(textVariants({ variant: "P2" }))}>
                  <Link
                    href={{
                      pathname: "/users/[id]",
                      query: { id: activityItem.user.id.toString() },
                    }}
                  >
                    <span className="hover:underline">
                      {activityItem.user.name}
                    </span>
                  </Link>
                  <span className="text-gray-8">
                    {" "}
                    {recentActivityTypeToText[activityItem.type]}
                  </span>{" "}
                  <Link
                    href={{
                      pathname: "/library/library-item/[id]",
                      query: {
                        id: activityItem.libraryItem.id.toString(),
                        mainTab: "manage",
                        manageTab: "assignments",
                      },
                    }}
                  >
                    <span className="hover:underline">
                      {activityItem.libraryItem.name.en}
                    </span>
                  </Link>
                  <span className="text-gray-8">
                    {" "}
                    {LibraryItemTypeToText[activityItem.libraryItem.type]}
                  </span>
                </p>
              </div>
            ))}
          </div>
        </>
      )}
      {loading && <Skeleton className="min-h-[278px] w-full rounded-xl" />}
    </Widget>
  );
};

gql`
  query ActivityWidget {
    RecentActivity {
      user {
        id
        name
      }
      libraryItem {
        id
        name {
          en
        }
        type
      }
      type
      datetime
    }
  }
`;

export default ActivityWidget;
