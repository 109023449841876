import { usePrevious } from "@hooks/usePrevious";
import { EditorContent, useEditor } from "@tiptap/react";
import { css, StyleDeclaration } from "aphrodite";
import { FC, useContext, useEffect, useState } from "react";
import useMentionConfig from "@src/components/libraryItemDetailPages/course/build/comments/mentions/hooks/useMentionConfig";
import { TiptapEditorContext } from "@src/components/libraryItemDetailPages/course/contexts/TiptapEditorContext";
import Text, { textVariants } from "@ui/text";
import { isRichTextEmpty } from "@src/components/libraryItemDetailPages/course/utils/utils";
import { LinkEditor } from "./LinkEditor";
import { useBodyCursorType } from "@src/contexts/BodyCursorTypeContext";
import { cn } from "@src/ui/lib/utils";
import useRichTextEditorExtensions, {
  LIST_INDENTATION_LEVELS,
} from "../hooks/useRichTextEditorExtensions";

type Props = {
  text: string;
  hasEditContentPermission: boolean;
  onChange?: (text: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  placeholder?: string;
  disabled?: boolean;
  styles?: StyleDeclaration;
  focusStyles?: StyleDeclaration;
  className?: string;
  editorVariant?: "default" | "minimal-headings";
  autoFocus?: boolean;
  enableMentions?: boolean;
  dataTestId?: string;
  transformPastedText?: (text: string) => string;
  listIndentation?: LIST_INDENTATION_LEVELS;
};

const RichTextEditor: FC<Props> = ({
  text,
  hasEditContentPermission,
  onChange,
  onFocus = () => {},
  onBlur = () => {},
  placeholder = "Type here...",
  disabled,
  styles: styleOverrides,
  focusStyles: focusStyleOverrides,
  className,
  autoFocus = false,
  enableMentions = false,
  dataTestId,
  transformPastedText,
  listIndentation,
  editorVariant = "default",
}) => {
  const textWithNewLines = text;
  const [newEditorValue, setNewEditorValue] = useState(textWithNewLines);
  const bodyCursorType = useBodyCursorType();
  const tiptapEditorContext = useContext(TiptapEditorContext);
  const mentionsConfig = useMentionConfig(tiptapEditorContext?.editorRef);

  const extensions = useRichTextEditorExtensions({
    placeholder,
    listIndentation,
    enableMentions,
    mentionsConfig,
  });

  const editorEditable = !disabled && hasEditContentPermission;
  const editor = useEditor({
    extensions: extensions,
    content: textWithNewLines,
    onUpdate({ editor }) {
      const html = editor.getHTML();
      if (textWithNewLines !== html) {
        if (isRichTextEmpty(html) && isRichTextEmpty(text)) return;
        onChange?.(html);
        setNewEditorValue(html);
      }
    },
    parseOptions: {
      preserveWhitespace: true,
    },
    onFocus: () => {
      onFocus();
      tiptapEditorContext?.setEditorIsFocused(true);
      if (bodyCursorType) {
        bodyCursorType.setCursorType("text");
      }
    },
    onBlur: () => {
      onBlur();
      tiptapEditorContext?.setEditorIsFocused(false);
      if (bodyCursorType) {
        bodyCursorType.setCursorType("auto");
      }
    },
    onTransaction: ({ editor }) => {
      tiptapEditorContext?.formatting.setIsBold(editor.isActive("bold"));
      tiptapEditorContext?.formatting.setIsItalic(editor.isActive("italic"));
      tiptapEditorContext?.formatting.setIsBulletList(
        editor.isActive("bulletList"),
      );
      tiptapEditorContext?.formatting.setIsOrderedList(
        editor.isActive("orderedList"),
      );
      tiptapEditorContext?.formatting.setIsTable(editor.isActive("table"));
    },
    editable: editorEditable,
    autofocus: autoFocus,
    editorProps: {
      transformPastedText: transformPastedText
        ? (text) => {
            return transformPastedText(text);
          }
        : undefined,
    },
  });

  const prevText = usePrevious(textWithNewLines);

  useEffect(() => {
    if (prevText !== textWithNewLines && textWithNewLines !== newEditorValue) {
      setNewEditorValue(textWithNewLines);
    }
  }, [textWithNewLines, prevText, newEditorValue]);

  useEffect(() => {
    if (
      prevText !== textWithNewLines &&
      textWithNewLines !== editor?.getHTML()
    ) {
      editor?.commands?.setContent(textWithNewLines);
    }
  }, [textWithNewLines, prevText, editor, newEditorValue]);

  if (!tiptapEditorContext) {
    return <Text type={"P2"}>Please initialize RichTextEditorContext</Text>;
  }

  return (
    <>
      <EditorContent
        editor={editor}
        spellCheck
        className={cn(
          "flex max-w-full flex-1 border-none p-0",
          textVariants({ variant: "training-content" }),
          css(styleOverrides, editor?.isFocused && focusStyleOverrides),
          className,
          "ProseMirror",
          editorVariant === "minimal-headings" &&
            "ProseMirror-minimal-headings",
        )}
        ref={tiptapEditorContext?.editorRef}
        value={newEditorValue}
        disabled={!editorEditable}
        placeholder={placeholder}
        data-testid={dataTestId || "rich-text-editor"}
      />
      {editor && (
        <div>
          <LinkEditor editor={editor} />
        </div>
      )}
    </>
  );
};

export default RichTextEditor;
