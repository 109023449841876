import { FC, useMemo } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { pluralize } from "@src/utils/strings";
import { AssignmentSummaryData } from "../hooks/useAssignmentSummaryData";

type AssignmentSummaryProps = {
  summaryData: AssignmentSummaryData;
  shouldRestart: boolean;
  partialModuleReassignment: boolean;
};

const AssignmentSummary: FC<AssignmentSummaryProps> = ({
  summaryData,
  shouldRestart,
  partialModuleReassignment,
}) => {
  const text: string[] = useMemo(() => {
    const {
      userIdsToAssignForFirstTime,
      userIdsStartedOrCompleted,
      userIdsNotStarted,
      userIdsNotCompleted,
      userIdsCompleted,
    } = summaryData;
    const text = [];
    if (userIdsToAssignForFirstTime.size > 0) {
      text.push(
        `${userIdsToAssignForFirstTime.size} ${pluralize(
          "user",
          userIdsToAssignForFirstTime.size,
        )} will be assigned for the first time.`,
      );
    }
    if (shouldRestart) {
      if (userIdsStartedOrCompleted.size > 0) {
        text.push(
          `${userIdsStartedOrCompleted.size} ${pluralize(
            "user",
            userIdsStartedOrCompleted.size,
          )} ${userIdsStartedOrCompleted.size > 1 ? "are" : "is"} in progress or completed and will be ${partialModuleReassignment ? "reassigned the specified content" : "re-assigned"}.`,
        );
      }
      if (userIdsNotStarted.size > 0) {
        text.push(
          `${userIdsNotStarted.size} ${pluralize(
            "user",
            userIdsNotStarted.size,
          )} ${userIdsNotStarted.size > 1 ? "have" : "has"} not started and will be re-notified.`,
        );
      }
    } else {
      if (userIdsNotCompleted.size > 0) {
        text.push(
          `${userIdsNotCompleted.size} ${pluralize(
            "user",
            userIdsNotCompleted.size,
          )} ${userIdsNotCompleted.size > 1 ? "have" : "has"} already been assigned and will not be reassigned.`,
        );
      }
      if (userIdsCompleted.size > 0) {
        text.push(
          `${userIdsCompleted.size} ${pluralize(
            "user",
            userIdsCompleted.size,
          )} ${
            userIdsCompleted.size > 1 ? "have" : "has"
          } already completed and will not be reassigned.`,
        );
      }
    }
    return text;
  }, [summaryData, shouldRestart, partialModuleReassignment]);
  return (
    <AutoLayout
      direction="vertical"
      spaceBetweenItems={4}
      padding={12}
      backgroundColor={deprecatedTones.blue2}
      borderRadius={8}
      className="self-stretch"
    >
      {text.map((t) => (
        <Text
          key={t}
          type="P2"
          color={deprecatedTones.blue12}
          fontWeight="Medium"
          multiline
        >
          • {t}
        </Text>
      ))}
    </AutoLayout>
  );
};

export default AssignmentSummary;
