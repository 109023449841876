import { useState } from "react";
import { FileUploadContextState } from "@src/contexts/FileUploadContext";

const useFileUploadContextState = (): FileUploadContextState => {
  const [uploadedFileKey, setUploadedFileKey] = useState<string | null>(null);
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  return {
    uploadedFileKey,
    setUploadedFileKey,
    uploadedFileName,
    setUploadedFileName,
    uploadedFile,
    setUploadedFile,
  };
};

export default useFileUploadContextState;
